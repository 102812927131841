<template lang="pug">
include ../pug/svg
section#theme
	nav.navbar.sticky
		div
			ul
				li(v-if="hasFeature('theme','style')")
					router-link(:to="{name:'ManageThemeStyle'}") Style
				li(v-if="hasFeature('theme','font')")
					router-link(:to="{name:'ManageThemeFont'}") Fonts
				li(v-if="hasFeature('theme','colour')")
					router-link(:to="{name:'ManageThemeColour'}") Colours
				li(v-if="hasFeature('theme','terminology')")
					router-link(:to="{name:'ManageThemeTerminology'}") Terminology
			button.but.pri.spinner(:class="{spin:saving}" type="button" :disabled="saving||!canSave" @click="saveTheme") Save
				+svg(svg-filename="spinner" aria-hidden="true" alt="Processing")
			button.but.pri.spinner(:class="{spin:resetting}" type="button" :disabled="resetting||!canCancel" @click="resetTheme") Cancel
				+svg(svg-filename="spinner" aria-hidden="true" alt="Processing")
				
	ModalConfirm(:active="leaveTo" :useDrag="true" @cancel="leaveTo=null" @confirm="resetTheme")
	
	router-view(v-slot="{Component}")
		transition(name="section" mode="out-in" enter-active-class="hide" leave-active-class="hide" appear)
			component(:is="Component" :cancelled="cancelled" @storeUpdated="storeUpdated($event)")
</template>

<script>
import OrganisationService from '../services/OrganisationService';
import ModalConfirm from '../components/ModalConfirm';
import { mapFields } from 'vuex-map-fields';

export default {
	name: 'ManageTheme',
	components: {
		ModalConfirm,
	},
	data() {
		return {
			updated: {
				style: null,
				font: null,
				pallet: null,
				terminology: null,
			},
			canSave: false,
			canCancel: false,
			cancelled: false,
			saving: false,
			resetting: false,
			leaveTo: null,
		}
	},
	beforeRouteLeave (to, from, next) {
		// unsaved changes
		if (this.canSave && !this.leaveTo) {
			this.leaveTo = to;
			this.$store.dispatch('gui/setTintState', true);
			next(false);
			
		} else {
			next();			
		}
	},
	computed: {
		...mapFields('gui', {
			// brand media
			awsS3KeyColour: 'organisation.awsS3KeyColour',
			awsS3KeyWhiteout: 'organisation.awsS3KeyWhiteout',
			awsS3Keyhero: 'organisation.awsS3Keyhero',
			logoColourUrl: 'organisation.logoColourUrl',
			logoWhiteoutUrl: 'organisation.logoWhiteoutUrl',
			heroUrl: 'organisation.heroUrl',
		}),
	},
	methods: {
		hasFeature(feature, subfeature) {
			return this.$store.getters['gui/hasFeature'](feature, subfeature);
		},
		storeUpdated({screen, valid}) {
			// log screen updated for efficient save
			this.updated[screen] = valid;
			
			let validSet = null;
			
			for(let screen in this.updated) {
				if (this.updated[screen] === false) {
					validSet = false;
					
				} else if (this.updated[screen] === true && validSet !== false) {
					validSet = true;
				}
			}
			// enable save button
			this.canSave = validSet ? true : false;
			this.canCancel = this.canSave;
		},
		async saveTheme() {
			console.log('saveTheme()');

			try {
				this.saving = true;
				const delay = this.$store.getters['gui/delay'];
				
				const themeData = {
					authScreen: this.$store.state.gui.authScreen,
					icon: this.$store.state.gui.icon,
					cssClass: this.$store.state.gui.cssClass,
					cssStyle: this.$store.state.gui.cssStyle,
					cssFont: this.$store.state.gui.cssFont,
					cssPallet: this.$store.state.gui.cssPallet,
					cssPalletMap: this.$store.state.gui.cssPalletMap,
					termMap: this.$store.state.gui.termMap,
				};
				
				// save theme to db
				const response = await OrganisationService.updateThemeBySubdomain({
					themeData,
					logoColourUrl: this.$store.state.gui.organisation.logoColourUrl,
					logoWhiteoutUrl: this.$store.state.gui.organisation.logoWhiteoutUrl,
					heroUrl: this.$store.state.gui.organisation.heroUrl,
				});
console.log('saveTheme()>response:', response);
				// update values from aws
				this.awsS3KeyColour = response.data.awsS3KeyColour;
				this.awsS3KeyWhiteout = response.data.awsS3KeyWhiteout;
				this.awsS3Keyhero = response.data.awsS3Keyhero;				
				this.logoColourUrl = response.data.logoColourUrl;
				this.logoWhiteoutUrl = response.data.logoWhiteoutUrl;
				this.heroUrl = response.data.heroUrl;
				
				await delay;
				
				if (response.status === 200) {
					this.resetFlags();
				}
				
			} catch(err) {
				// handle error
				console.log('saveTheme()>err:', err.response.status, err);				
			}
		},
		async resetTheme() {
			try {
				this.reseting = true;
				const delay = this.$store.getters['gui/delay'];
				
				await this.$store.dispatch('gui/initOrganisation');
				
				await delay;
				
				this.resetFlags();
				
			} catch(err) {
				// handle error
				console.log('resetTheme()>err:', err.response.status, err);				
			}
		},
		resetFlags() {
			// reset
			this.updated.style = null;
			this.updated.font = null;
			this.updated.pallet = null;
			this.updated.terminology = null;
			this.canSave = false;
			this.canCancel = false;
			this.saving = false;
			this.resetting = false;
		},
	},
}
</script>

<style lang="scss">
#theme {
	.navbar ul {
		max-width: none;
	}
}
</style>
